import { setDefaultLocale } from "@emanprague/ppaskit/dist/DatePicker";
import { configure, isObservableArray } from "mobx";
import { initializeMonitoring, installMonitoring } from "monitoring";
import "reflect-metadata";
import runApp from "./app";
import "./assets/themes/default.theme.scss";
import createContainer from "./di.config";
import cs from "./models/fixedCsLocale";
import * as serviceWorker from "./serviceWorker";
import "./views";

installMonitoring();

const apiUrl = `${import.meta.env.REACT_APP_API_URL}${import.meta.env.REACT_APP_API_ENDPOINT}`;
const container = createContainer(apiUrl);

initializeMonitoring(container);

// Move this to localizationService when multiple locales is needed
setDefaultLocale(cs as any);

configure({
  enforceActions: "observed",
});

// monkey patch the Array.isArray to support observable arrays (until mobx 6 is out or class-transformer fixed)
const originalIsArray = Array.isArray;
Array.isArray = function (arg: any): arg is any[] {
  return originalIsArray(arg) || isObservableArray(arg);
};

runApp(container);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

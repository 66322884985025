import { IPagingFilter } from "@frui.ts/data";
import Invoice from "entities/invoice";
import InvoiceList from "entities/invoiceList";
import PaymentGateway from "entities/paymentGateway";
import ReqInvoiceComplaint from "entities/reqInvoiceComplaint";
import InvoicesFilter from "models/invoicesFilter";
import { RequestsEvents } from "services/events";
import { entityToPlain } from "./helpers";
import RepositoryBase from "./repositoryBase";
import ReqOnlinePayment from "entities/reqOnlinePayment";
import ReqExport from "entities/reqExport";

export default class InvoicesRepository extends RepositoryBase {
  getInvoices(partnerId: number, paging: IPagingFilter, filter?: InvoicesFilter) {
    const query = entityToPlain(filter);

    return this.callApi(api =>
      api.all(`partners/${partnerId}/invoices`).getPagedEntities(InvoiceList, x => x.invoices, paging, query)
    );
  }

  getInvoiceDetail(partnerId: number, invoiceId: number) {
    return this.callApi(api => api.all(`partners/${partnerId}/invoices/${invoiceId}`).getEntity(Invoice));
  }

  setInvoicePay(partnerId: number, invoiceId: number, gatewayMethod: ReqOnlinePayment) {
    return this.callApi(api =>
      api.all(`partners/${partnerId}/invoices/${invoiceId}/online_pay`).postEntity(gatewayMethod, PaymentGateway)
    );
  }

  getQrCodeUrl(partnerId: number, invoiceId: number) {
    return (
      `${import.meta.env.REACT_APP_API_URL}${import.meta.env.REACT_APP_API_ENDPOINT}` +
      `/partners/${partnerId}/invoices/${invoiceId}/qr_code`
    );
  }

  postInvoiceComplaint(partnerId: number, invoiceId: number, complaint: ReqInvoiceComplaint) {
    const formData = complaint.toFormData();
    const promise = this.callApi(api => api.all(`partners/${partnerId}/invoices/${invoiceId}/claim`).postData(formData));
    promise.then(() => this.eventBus.publish(RequestsEvents.requestCreated(undefined)));

    return promise;
  }

  exportInvoices(partnerId: number, reqExport: ReqExport) {
    return this.callApi(api => api.one("partners", partnerId).all("invoices/export").postEntity(reqExport));
  }
}

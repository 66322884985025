import { IEventBus } from "@emanprague/shared-services";
import { FetchApiConnector, handleErrorStatusMiddleware } from "@frui.ts/apiclient";
import { bound } from "@frui.ts/helpers";
import { GeneralEvents } from "services/events";
import DeserializingRequestBuilder from "./deserializingRequestBuilder";
import { serializeEntity } from "./helpers";

function versionCheckingMiddleware(response: Response) {
  if (response.status === 412) {
    // wait some time before reloading so that the user can see the message if anything goes wrong
    setTimeout(() => window.location.reload(), 3000);
  }

  return response;
}

function readCookie(name: string): string | undefined {
  const nameEQ = `${name}=`;
  for (const cookie of document.cookie.split(";")) {
    const c = cookie.trim();
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }

  return undefined;
}

export default class BackendConnector {
  constructor(private baseUrl: string, private eventBus: IEventBus) {}

  @bound
  getRequestBuilder() {
    const apiConnector = new FetchApiConnector({
      jsonSerializer: serializeEntity,
      middleware: this.middleware,
    });

    // Prepare request headers
    const requestHeaders: HeadersInit = {};

    if (import.meta.env.REACT_APP_VERSION) {
      requestHeaders["Accept-Version"] = import.meta.env.REACT_APP_VERSION;
    }

    const token = readCookie("X-CSRF-Token");
    if (token) {
      requestHeaders["X-CSRF-Token"] = decodeURIComponent(token);
    }

    const params: RequestInit = {
      headers: requestHeaders,
    };

    return new DeserializingRequestBuilder(apiConnector, this.baseUrl, params);
  }

  @bound
  private middleware(response: Response) {
    return handleErrorStatusMiddleware(this.loggedOutMiddleware(versionCheckingMiddleware(response)));
  }

  private loggedOutMiddleware(response: Response) {
    if (response.status === 401) {
      this.eventBus.publish(GeneralEvents.unauthorized(null));
    }

    return response;
  }
}

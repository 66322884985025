import { Button, ButtonTooltip, HeaderRow, LoaderOverlay, SwitchNav } from "@emanprague/ppaskit";
import type { ViewComponent } from "@frui.ts/views";
import { preventDefault, registerView, View } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { ResponsiveTable } from "@frui.ts/dataviews";
import type { DataColumn } from "controls/tableHelper";
import { createColumnsContext } from "controls/tableHelper";
import { CollectionCheck, Input } from "@frui.ts/bootstrap";
import "./style.scss";
import EmptyDataMessage from "components/EmptyDataMessage";
import iconCheck from "@emanprague/ppaskit/dist/assets/icons/CheckSelected.svg";
import type AuditLogListItem from "entities/auditLogListItem";
import AuditLogsPageViewModel from "viewModels/admin/auditLogsPageViewModel";
import FilterButton from "controls/filterButton";
import Tooltip from "@emanprague/ppaskit/dist/Tooltip";

const columns: DataColumn<AuditLogListItem, AuditLogsPageViewModel>[] = [
  {
    property: "status",
    titleFactory: ({ vm, ta }) => (
      <>
        {ta("audit_log", "status")}
        <FilterButton
          id="roleFilter"
          isActive={vm.selectedStatuses.length > 0}
          clearLabel={vm.translateGeneral("cancel_button")}
          confirmLabel={vm.translateGeneral("confirm")}
          onClear={() => vm.clearFilter("status")}
          onConfirm={vm.applyFilterAndLoad}>
          {vm.auditLogStatuses.map((item, index) => (
            <CollectionCheck
              custom
              key={index}
              target={vm}
              property="selectedStatuses"
              value={item.id}
              label={item.name}
              onValueChanged={vm.applyStatusesFilter}
              className="mt-1"
            />
          ))}
        </FilterButton>
      </>
    ),
    valueFormatter: ({ context, item }) => {
      const body = <span>{context.vm.translate(`status.${item.status}`)}</span>;

      if (item.isResolved) {
        return (
          <Tooltip id="status-tooltip" tooltip={<>{context.vm.localization.formatDateTime(item.resolvedAt)}</>}>
            {body}
          </Tooltip>
        );
      } else {
        return body;
      }
    },
  },
  {
    property: "partnerExternalId",
    titleFactory: ({ vm, ta }) => (
      <>
        {ta("audit_log", "id")}
        <FilterButton
          id="partnerFilter"
          isActive={!!vm.filter.partnerExternalId}
          clearLabel={vm.translateGeneral("cancel_button")}
          confirmLabel={vm.translateGeneral("confirm")}
          onClear={() => vm.clearFilter("partnerExternalId")}
          onConfirm={vm.applyFilterAndLoad}>
          <Input target={vm.filter} property="partnerExternalId" placeholder={vm.translateGeneral("search")} />
        </FilterButton>
      </>
    ),
    valueFormatter: ({ item }) => (
      <Tooltip id="partner-tooltip" tooltip={<>{item.user_email}</>}>
        <span>{item.partnerExternalId}</span>
      </Tooltip>
    ),
  },
  {
    property: "lastOccurrenceAt",
    titleFactory: ({ ta }) => <>{ta("audit_log", "last_occurrence_at")}</>,
    valueFormatter: ({ context, item }) => (
      <Tooltip
        id="last-occurrence-tooltip"
        tooltip={
          <>
            {context.vm.translate("occurrences")}: {item.occurrences}
          </>
        }>
        <span>{context.vm.localization.formatDateTime(item.lastOccurrenceAt)}</span>
      </Tooltip>
    ),
  },
  {
    property: "message",
    titleFactory: ({ vm, ta }) => (
      <>
        {ta("audit_log", "message")}
        <FilterButton
          id="messageFilter"
          isActive={!!vm.filter.message}
          clearLabel={vm.translateGeneral("cancel_button")}
          confirmLabel={vm.translateGeneral("confirm")}
          onClear={() => vm.clearFilter("message")}
          onConfirm={vm.applyFilterAndLoad}>
          <Input target={vm.filter} property="message" placeholder={vm.translateGeneral("search")} />
        </FilterButton>
      </>
    ),
    valueFormatter: ({ item }) => {
      if (!item.message) {
        return undefined;
      }
      return (
        <Tooltip id="message-tooltip" tooltip={item.message}>
          <span>{item.message}</span>
        </Tooltip>
      );
    },
    cellClassName: "audit-log-message-cell",
  },
  {
    responsiveTitle: false,
    valueFormatter: ({ item, context }) => {
      if (item.isResolved) {
        return <></>;
      } else {
        return (
          <span className="text-nowrap">
            <ButtonTooltip tooltip={context.vm.translateGeneral("resolve")} last>
              <Button
                size="sm"
                variant="secondary"
                className="btn-responsive-block square-button"
                onClick={() => void context.vm.resolveItem(item.id)}>
                <img src={iconCheck} width={18} height={18} alt="" />
              </Button>
            </ButtonTooltip>
          </span>
        );
      }
    },
    cellClassName: "cell-right",
  },
];

const auditLogsPageView: ViewComponent<AuditLogsPageViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg } = vm.localization;

  const links = [
    {
      id: "all",
      title: vm.translate("status.all"),
      href: "#",
      onClick: preventDefault(() => vm.selectStatuses([])),
    },
    {
      id: "unresolved",
      title: vm.translate("status.unresolved"),
      href: "#",
      onClick: preventDefault(() => vm.selectStatuses(["unresolved"])),
    },
    {
      id: "resolved",
      title: vm.translate("status.resolved"),
      href: "#",
      onClick: preventDefault(() => vm.selectStatuses(["resolved"])),
    },
    {
      id: "auto_resolved",
      title: vm.translate("status.auto_resolved"),
      href: "#",
      onClick: preventDefault(() => vm.selectStatuses(["auto_resolved"])),
    },
  ];

  return (
    <>
      <HeaderRow level={2} title={vm.name} className="mx-1 mx-sm-n3"></HeaderRow>
      <Row className="mt-4">
        <Col xs={12}>
          <Card>
            <Card.Body>
              <LoaderOverlay loading={vm.busyWatcher.isBusy}>
                <Row className="mb-3 justify-content-between align-items-center">
                  <Col xs={12} md={6} lg="auto" className="py-2 mb-2 mb-md-0">
                    <SwitchNav links={links} activeId={vm.activeStatusCard} size="sm" />
                  </Col>
                  <Col xs={12} md={5} className="py-2">
                    <Input
                      size="sm"
                      target={vm.filter}
                      property="search"
                      placeholder={vm.translate("search_placeholder")}
                      onValueChanged={vm.applyFilterAndLoadDebounced}
                    />
                  </Col>
                </Row>

                <div className="responsive-table-wrapper">
                  <ResponsiveTable
                    className="table no-wrap"
                    items={vm.items}
                    itemKey="id"
                    columns={columns}
                    context={createColumnsContext(vm)}
                  />
                </div>
                {!vm.items?.length && <EmptyDataMessage translateGeneral={tg} />}

                {vm.canLoadData && (
                  <div className="load-more-button">
                    <Button onClick={vm.loadData} variant="secondary">
                      {tg("general.button_load_more")}
                    </Button>
                  </div>
                )}

                <View vm={vm.activeChild} />
              </LoaderOverlay>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
});

registerView(auditLogsPageView, AuditLogsPageViewModel);

import type { ViewComponent } from "@frui.ts/views";
import { registerView, View } from "@frui.ts/views";
import { observer, Observer } from "mobx-react-lite";
import React from "react";
import CustomerAccountViewModel from "viewModels/dashboard/customerAccountViewModel";
import { Row, Col, Button, Card, HeaderRow, Layout } from "@emanprague/ppaskit";
import iconEdit from "@emanprague/ppaskit/dist/assets/icons/Edit.svg";
import iconInfo from "@emanprague/ppaskit/dist/assets/icons/Info.svg";
import Tooltip from "@emanprague/ppaskit/dist/Tooltip";
import { addressToHuman } from "helpers/utils";
import SupplyPointInline from "controls/supplyPointInline";
import { HistoricalSupplyPointsCard } from "./widgets/historicalSupplyPointsCard";
import customerNumberImg from "assets/images/img-cislo-zakaznika.png"
import customerNumberImg2 from "assets/images/img-cislo-zakaznika@2x.png"
import customerNumberImg3 from "assets/images/img-cislo-zakaznika@3x.png"

const customerAccountView: ViewComponent<CustomerAccountViewModel> = observer(({ vm }) => {
  const ta = vm.localization.translateAttribute;
  return (
    <Layout fluid="xl" className="mt-5">
      <h1 className="display-1 mb-4 pl-4 pl-sm-0 text-sm-center">{vm.translate("customer_account")}</h1>
      <Row className="mb-3">
        <Col lg={8} className="mx-auto">
          <Card>
            <Card.Body className="mx-2 mx-sm-0">
              <Row>
                <Col xs={12} md={6}>
                  <HeaderRow level={3} title={vm.translate("title")}>
                    <Button variant="secondary" size="sm" onClick={vm.activateEditCustomerInfoModal}>
                      <img src={iconEdit} width={16} height={16} alt="" className="mr-2" />
                      {vm.translateGeneral("edit_button")}
                    </Button>
                  </HeaderRow>

                  <table className="table wider-row">
                    <tbody>
                      <tr>
                        <th>
                          {vm.translate("customer_number")}&nbsp;
                          <Tooltip
                            id="cacntooltip"
                            tooltip={
                              <>
                                <div className="font-size-base font-weight-bold mb-2 w-75">
                                  {vm.translateGeneral("customer_number_tooltip_head")}
                                </div>
                                <div className="mb-3 w-75">{vm.translateGeneral("customer_number_tooltip_body")}</div>
                                <img
                                  className="mb-n3"
                                  width={300}
                                  sizes="300px"
                                  src={customerNumberImg}
                                  srcSet={`${customerNumberImg} 1x, ${customerNumberImg2} 2x, ${customerNumberImg3} 3x`}
                                  alt=""
                                />
                              </>
                            }>
                            <img src={iconInfo} alt="" width={22} height={22} className="align-top" />
                          </Tooltip>
                        </th>
                        <td>{vm.partner?.externalId}</td>
                      </tr>
                      <tr>
                        <th>{vm.translate("customer")}</th>
                        <td>{vm.activePartnerFullName}</td>
                      </tr>
                      {vm.partner?.orgId && (
                        <tr>
                          <th>{vm.localization.translateAttribute("partner", "org_id")}</th>
                          <td>{vm.partner?.orgId}</td>
                        </tr>
                      )}
                      {vm.partner?.vatId && (
                        <tr>
                          <th>{vm.localization.translateAttribute("partner", "vat_id")}</th>
                          <td>{vm.partner?.vatId}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <HeaderRow title={vm.translate("logging_email")} level={3} className="mt-5">
                    <Button variant="secondary" size="sm" onClick={vm.activateUpdateEmailModal}>
                      <img src={iconEdit} width={16} height={16} alt="" /> {vm.translateGeneral("edit_button")}
                    </Button>
                  </HeaderRow>
                  <div className="font-size-sm">{vm.user?.email}</div>

                  {vm.partner?.marketingAgreementVisible && (
                    <>
                      <HeaderRow title={vm.translate("agreements")} level={3} className="mt-5">
                        <Button variant="secondary" size="sm" onClick={vm.activateEditMarketingAgreementModal}>
                          <img src={iconEdit} width={16} height={16} alt="" />{" "}
                          {vm.localization.translateGeneral("general.manage_button")}
                        </Button>
                      </HeaderRow>
                      <div className="font-size-sm mb-5 mb-md-0">
                        {vm.translate(vm.partner?.marketingAgreement ? "accepted_agreement" : "not_accepted_agreement")}
                      </div>
                    </>
                  )}
                </Col>
                <Col xs={12} md={6}>
                  <HeaderRow title={vm.translate("contact_info")} level={3}>
                    <Button variant="secondary" size="sm" onClick={vm.activateEditContactInfoModal}>
                      <img src={iconEdit} width={16} height={16} alt="" /> {vm.translateGeneral("edit_button")}
                    </Button>
                  </HeaderRow>

                  <table className="table wider-row">
                    <tbody>
                      <tr>
                        <th>{ta("user", "email")}</th>
                        <td>{vm.partner?.email}</td>
                      </tr>
                      <tr>
                        <th>{ta("user", "phone")}</th>
                        <td>{vm.partner?.phone}</td>
                      </tr>
                      <tr>
                        <th>{vm.translate(vm.isCompany ? "company_address" : "permanent_address")}</th>
                        <td>{addressToHuman(vm.partner?.permanentAddress)}</td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          {vm.showHistoricalSupplyPointsCard && <HistoricalSupplyPointsCard vm={vm} />}

          <Card>
            <Card.Body>
              <Row className="justify-content-between align-items-center mb-3">
                <Col xs="auto">
                  <HeaderRow title={vm.translate("postal_addresses")} level={4} />
                </Col>
              </Row>

              <table className="table responsive-col2row last-column-right font-size-sm wider-row">
                <thead>
                  <tr>
                    <th>{vm.localization.translateModel("supply_point", 1)}</th>
                    <th>{vm.translate("mailing_address")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {vm.postalAddresses.map(item => (
                    <tr key={`sp-${item.supplyPoint.id}-${item.id}`}>
                      <td data-title={vm.localization.translateModel("supply_point", 1)}>
                        <SupplyPointInline
                          supplyPoint={item.supplyPoint}
                          commodityType={vm.enumsService.getCommodityType(item.supplyPoint.commodityId)}
                        />
                      </td>
                      <td data-title={vm.translate("mailing_address")}>{addressToHuman(item)}</td>
                      <td>
                        <Button variant="secondary" size="sm" onClick={() => vm.activateEditMailingAddressModal(item)}>
                          <img src={iconEdit} width={16} height={16} alt="" className="mr-2" />
                          {vm.translateGeneral("edit_button")}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Observer>{() => <View vm={vm.activeChild} />}</Observer>
    </Layout>
  );
});

registerView(customerAccountView, CustomerAccountViewModel);

import { IEventBus, ILocalizationService } from "@emanprague/shared-services";
import type { IDisposable } from "@frui.ts/helpers";
import { bound } from "@frui.ts/helpers";
import type { ScreenBase } from "@frui.ts/screens";
import { BusyWatcher, ConductorSingleChild, Router, watchBusy } from "@frui.ts/screens";
import type AppendixListAppendixesItem from "entities/appendixListAppendixesItem";
import Contract from "entities/contract";
import { createURLQuery, openInNewTab } from "helpers/utils";
import { observable, runInAction, action } from "mobx";
import ContractRepository from "repositories/contractRepository";
import AppendixesService from "services/appendixesService";
import { AppendixesEvents } from "services/events";
import UserContext from "services/userContext";
import type { IDetailPage } from "viewModels/types";
import type ProductDetailViewModel from "../../productDetailViewModel";
import type NewAppendixListItem from "entities/newAppendixListItem";
import type ModalDocumentsViewModel from "./modalDocumentsViewModel";
import HistoricalSupplyPointsService from "services/historicalSupplyPointsService";

const navigationName = "contract";

@Router.registerRoute({ name: Router.Self, route: `${navigationName}` })
export default class ContractDetailPageViewModel extends ConductorSingleChild<ScreenBase> implements IDetailPage {
  orderIndex = 6;
  navigationName = navigationName;
  className = "icon-document";

  @observable contract: Contract;
  @observable newAppendixes: NewAppendixListItem[];
  @observable appendixes: AppendixListAppendixesItem[];

  busyWatcher = new BusyWatcher();
  parent: ProductDetailViewModel;

  private eventHandlers: IDisposable[];

  constructor(
    public localization: ILocalizationService,
    private contractRepository: ContractRepository,
    private appendixesService: AppendixesService,
    private userContext: UserContext,
    private eventBus: IEventBus,
    private modalDocumentsViewModelFactory: ReturnType<typeof ModalDocumentsViewModel.Factory>,
    private historicalSupplyPointsService: HistoricalSupplyPointsService
  ) {
    super();

    this.name = this.translate("title");
  }

  protected onInitialize() {
    this.eventHandlers = [this.eventBus.subscribe(AppendixesEvents.appendixesUpdated, () => this.loadData())];

    return this.loadData();
  }

  protected async onDeactivate(isClosing: boolean) {
    await super.onDeactivate(isClosing);

    if (isClosing) {
      this.eventHandlers?.forEach(x => x.dispose());
    }
  }

  @bound
  @watchBusy
  private async loadData() {
    const { supplyPointId, partnerId } = this.parent.productDetailContext;
    if (!partnerId || !supplyPointId) {
      return;
    }

    const [resultContract, resultNewAppendixes, resultAppendixes] = await Promise.all([
      this.contractRepository.getContract(partnerId, supplyPointId),
      this.appendixesService.getNewAppendixes(partnerId, supplyPointId),
      this.appendixesService.getAppendixes(partnerId, supplyPointId),
    ]);

    runInAction(() => {
      if (resultContract.success) {
        this.contract = resultContract.payload;
      }
      if (resultNewAppendixes.success) {
        this.newAppendixes = resultNewAppendixes.payload;
      }
      if (resultAppendixes.success) {
        this.appendixes = this.sortAppendixes(resultAppendixes.payload);
      }
    });
  }

  sortAppendixes(appendixes: AppendixListAppendixesItem[]): AppendixListAppendixesItem[] {
    const sortNewestFirst = (items: Array<any>) => [...items].sort((a, b) => b.dateFrom.valueOf() - a.dateFrom.valueOf());
    const today = new Date();
    const activeAppendixes = appendixes.filter(x => !x.dateTo || x.dateTo >= today);
    const inactiveAppendixes = appendixes.filter(x => x.dateTo && x.dateTo < today);

    return [...sortNewestFirst(activeAppendixes), ...sortNewestFirst(inactiveAppendixes)];
  }

  @bound
  activateDocumentsListModal(appendix: NewAppendixListItem | AppendixListAppendixesItem, confirmMode: boolean) {
    const { partnerId, supplyPointId } = this.parent.productDetailContext;
    if (!partnerId || !supplyPointId) {
      return;
    }

    return this.tryActivateChild(this.modalDocumentsViewModelFactory(appendix, partnerId, supplyPointId, confirmMode));
  }

  get supplyPoint() {
    return this.parent.productDetailContext.supplyPoint;
  }

  @bound
  prolongContract() {
    if (!this.supplyPoint) {
      return;
    }

    const params = {
      token: this.userContext.user?.token,
      accountid: this.supplyPoint.account.externalId,
      "e-mail": this.userContext.user?.email,
      podid: this.supplyPoint.code,
      process: "prolongation",
    };
    return openInNewTab((import.meta.env.REACT_APP_SAM_URL ?? "") + "?" + createURLQuery(params));
  }

  get isProlongationActive() {
    return !import.meta.env.REACT_APP_DISABLE_TRANSFER && this.contract && this.contract.active && !this.contract.dateTo;
  }

  @bound
  @action
  async toggleSupplyPoint(pinned: boolean) {
    const { partnerId } = this.parent.productDetailContext;
    if (!this.supplyPoint) {
      return;
    }

    this.supplyPoint.pinned = pinned;

    await this.historicalSupplyPointsService.updateHistoricalSupplyPoint(partnerId, this.supplyPoint);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`products.contract.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  protected findNavigationChild(_navigationName: string | undefined) {
    return undefined;
  }
}

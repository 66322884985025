import { Button, Card, CardGroup, Col, Form, LoaderOverlay } from "@emanprague/ppaskit";
import Stepper from "@emanprague/ppaskit/dist/Stepper";
import { Input, Check } from "@frui.ts/bootstrap";
import { preventDefault, registerView, View, ViewComponent } from "@frui.ts/views";
import InfoInput from "controls/twoway/InfoInput";
import { observer } from "mobx-react-lite";
import React from "react";
import RegistrationViewModel from "viewModels/profile/registrationViewModel";
import PreAuthHeader from "components/PreAuthHeader";
import customerNumberImg from "assets/images/img-cislo-zakaznika.png";
import customerNumberImg2 from "assets/images/img-cislo-zakaznika@2x.png";
import customerNumberImg3 from "assets/images/img-cislo-zakaznika@3x.png";
import contractNumberImg from "assets/images/img-cislo-smlouvy.png";
import contractNumberImg2 from "assets/images/img-cislo-smlouvy@2x.png"
import contractNumberImg3 from "assets/images/img-cislo-smlouvy@3x.png"

const registrationListView: ViewComponent<RegistrationViewModel> = observer(({ vm }) => (
  <>
    <PreAuthHeader translateFunc={vm.localization.translateGeneral} title={vm.name} />
    <View vm={vm} context={vm.currentStep} />
  </>
));

registerView(registrationListView, RegistrationViewModel);

const step1: ViewComponent<RegistrationViewModel> = observer(({ vm }) => {
  const ta = vm.localization.translateAttribute;

  return (
    <>
      <Stepper step={1} steps={vm.steps} />
      <CardGroup className="card-single">
        <Card>
          <LoaderOverlay loading={vm.busyWatcher.isBusy}>
            <Card.Body>
              {/** There was DPI note. If you ever need it again, just check git history */}
              {import.meta.env.REACT_APP_BANK_ID_URL && (
                <>
                  <Button href={import.meta.env.REACT_APP_BANK_ID_URL} variant="secondary" block={true} className="mb-2">
                    {vm.translate("registration_via_bank_id")}
                  </Button>
                  <div className="d-flex">
                    <hr className="my-auto flex-grow-1" />
                    <div className="px-4">{vm.translateGeneral("or")}</div>
                    <hr className="my-auto flex-grow-1" />
                  </div>
                </>
              )}

              <Form onSubmit={preventDefault(vm.continue)}>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>{ta("partner", "external_id")}</Form.Label>
                    <InfoInput
                      target={vm.registrationData}
                      property="customerNumber"
                      autoFocus
                      type="number"
                      tooltip={
                        <>
                          <div className="font-size-base font-weight-bold mb-2 w-75">
                            {vm.translateGeneral("customer_number_tooltip_head")}
                          </div>
                          <div className="mb-3 w-75">{vm.translateGeneral("customer_number_tooltip_body")}</div>
                          <img
                            className="mb-n3"
                            width={300}
                            sizes="300px"
                            src={customerNumberImg}
                            srcSet={`${customerNumberImg} 1x, ${customerNumberImg2} 2x, ${customerNumberImg3} 3x`}
                            alt=""
                          />
                        </>
                      }
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>{ta("account", "external_id")}</Form.Label>
                    <InfoInput
                      target={vm.registrationData}
                      property="accountNumber"
                      type="number"
                      tooltip={
                        <>
                          <div className="font-size-base font-weight-bold mb-3">
                            {vm.translateGeneral("account_number_tooltip_head")}
                          </div>
                          <img
                            className="mb-n3"
                            width={300}
                            sizes="300px"
                            src={contractNumberImg}
                            srcSet={`${contractNumberImg} 1x, ${contractNumberImg2} 2x, ${contractNumberImg3} 3x`}
                            alt=""
                          />
                        </>
                      }
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} className="mb-4">
                    <Form.Label>{ta("user", "email")}</Form.Label>
                    <Input target={vm.registrationData} property="email" />
                    <span className="font-size-sm text-muted">{vm.translate("email_note")}</span>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col}>
                    <Check
                      custom
                      target={vm.registrationData}
                      property="zopAgreement"
                      label={
                        <>
                          {vm.translate("zop_agreement_part1")}{" "}
                          <a href={vm.translate("link_zoo")} target="_blank" rel="noopener noreferrer">
                            {vm.translate("zop_agreement_link1")}
                          </a>{" "}
                          {vm.translate("zop_agreement_part2")}{" "}
                          <a href={vm.translate("link_zou")} target="_blank" rel="noopener noreferrer">
                            {vm.translate("zop_agreement_link2")}
                          </a>{" "}
                        </>
                      }
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col}>
                    <Check
                      custom
                      target={vm.registrationData}
                      property="mktAgreement"
                      label={
                        <>
                          {vm.translateGeneral("mkt_agreement_text")}{" "}
                          <a href={vm.translateGeneral("link_mkt")} target="_blank" rel="noopener noreferrer">
                            {vm.translateGeneral("mkt_agreement_link")}
                          </a>{" "}
                          <span className="text-gray">{vm.translate("mkt_agreement_note")}</span>
                        </>
                      }
                    />
                  </Form.Group>
                </Form.Row>
                {vm.errorMessage && <Form.Control.Feedback type="invalid">{vm.errorMessage}</Form.Control.Feedback>}
                <Button disabled={!vm.canContinue} type="submit" block={true} className="mt-4">
                  {vm.localization.translateGeneral("general.continue_button")}
                </Button>
              </Form>
            </Card.Body>
          </LoaderOverlay>
        </Card>
      </CardGroup>
    </>
  );
});
registerView(step1, RegistrationViewModel, "step1");

const step2: ViewComponent<RegistrationViewModel> = observer(({ vm }) => (
  <>
    <Stepper step={2} steps={vm.steps} />
    <CardGroup className="card-single">
      <Card>
        <LoaderOverlay loading={vm.busyWatcher.isBusy}>
          <Card.Body className="text-center">
            <p dangerouslySetInnerHTML={{ __html: vm.successMessage ?? "" }} />
            {vm.errorMessage && <Form.Control.Feedback type="invalid">{vm.errorMessage}</Form.Control.Feedback>}
            {vm.canResend && (
              <Button variant="secondary" onClick={vm.resend}>
                {vm.translate("resend_button")}
              </Button>
            )}
          </Card.Body>
        </LoaderOverlay>
      </Card>
    </CardGroup>
  </>
));

registerView(step2, RegistrationViewModel, "step2");

import Tooltip from "@emanprague/ppaskit/dist/Tooltip";
import iconInfo from "@emanprague/ppaskit/dist/assets/icons/Info.svg";
import type PaymentItem from "entities/paymentItem";
import React from "react";
import "./style.scss";

interface PaymentItemsTooltipProps {
  items?: PaymentItem[];
  total_amount: number;
  translateGeneral: (code: string) => string;
  formatNumber: (amount: number) => string;
}

export default class PaymentItemsTooltip extends React.PureComponent<PaymentItemsTooltipProps> {
  render() {
    const { translateGeneral, formatNumber, total_amount, items } = this.props;

    const currency = translateGeneral("currency");

    let rows = undefined;
    if (items) {
      rows = items.map((item: PaymentItem, index) => (
        <tr key={index}>
          <th>{item.description}</th>
          <td>
            {formatNumber(item.amount)} {currency}
          </td>
        </tr>
      ));
    }

    return (
      <span className="subsidy-info-box">
        {rows && rows.length > 0 && (
          <Tooltip
            placement="top"
            id="subsidy-tooltip"
            defaultShow={false}
            tooltip={
              <>
                <table className="subsidy-details-table">
                  <tbody>
                    {rows}
                    <tr>
                      <th>Celkem</th>
                      <td>
                        {formatNumber(total_amount)} {currency}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            }>
            <span className="text-nowrap">
              <img src={iconInfo} alt="" width={22} height={22} className="align-top" />
            </span>
          </Tooltip>
        )}
      </span>
    );
  }
}

import { preventDefault } from "@frui.ts/views";
import FilterIcon from "@emanprague/ppaskit/dist/assets/icons/Filter.svg?react";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import ReactDOM from "react-dom";
import "./filterButton.scss";

const stopPropagation: React.MouseEventHandler<any> = e => e.stopPropagation();

export interface FilterButtonProps {
  id: string;
  confirmLabel?: string;
  clearLabel?: string;

  isActive?: boolean;
  canClear?: boolean;
  onClear?: () => any;
  onConfirm?: () => any;
}

const FilterButton: React.FC<FilterButtonProps> = observer(props => {
  const [isOpened, setOpened] = React.useState(false);
  const onConfirm = () => {
    props.onConfirm?.();
    setOpened(!isOpened);
  };

  const canClear = props.canClear ?? props.isActive;

  const portalContainer = document.getElementById("root");

  const popperElement = (
    <Dropdown.Menu
      className="filter-button__popup"
      popperConfig={{
        modifiers: [
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              escapeWithReference: false,
              boundariesElement: "scrollParent",
            },
          },
        ],
      }}>
      <Form onSubmit={preventDefault(onConfirm)} className="m-3">
        {props.children}
      </Form>
      <div className="filter-button__popup__footer">
        <Button variant="secondary" onClick={onConfirm}>
          {props.confirmLabel}
        </Button>
        {canClear && (
          <Button variant="light" onClick={props.onClear}>
            {props.clearLabel}
          </Button>
        )}
      </div>
    </Dropdown.Menu>
  );

  return (
    <div className="filter-button" onClick={stopPropagation}>
      <Dropdown show={isOpened} onToggle={setOpened}>
        <Dropdown.Toggle id={props.id} variant="outline-secondary" size="sm" active={props.isActive}>
          <FilterIcon className="filter-icon" />
        </Dropdown.Toggle>

        {portalContainer ? ReactDOM.createPortal(popperElement, portalContainer) : popperElement}
      </Dropdown>
    </div>
  );
});

export default FilterButton;

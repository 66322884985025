import { ILocalizationService } from "@emanprague/shared-services";
import { BusyWatcher, ConductorSingleChild, Router, ScreenBase } from "@frui.ts/screens";
import { watchBusy } from "@frui.ts/screens";
import type ModalEditContactInfoViewModel from "viewModels/products/myProducts/detailPages/customerInfo/modalEditContactInfoViewModel";
import type ModalEditMarketingAgreementViewModel from "viewModels/products/myProducts/detailPages/customerInfo/modalEditMarketingAgreementViewModel";
import type ModalEditMailingAddressViewModel from "viewModels/products/myProducts/detailPages/customerInfo/modalEditMailingAddressViewModel";
import UserContext from "services/userContext";
import { bound } from "@frui.ts/helpers";
import type UpdateEmailViewModel from "viewModels/profile/updateEmailViewModel";
import type ModalEditCustomerInfoViewModel from "../products/myProducts/detailPages/customerInfo/modalEditCustomerInfoViewModel";
import DataSyncService from "services/dataSyncService";
import type Address from "entities/address";
import EnumsService from "services/enumsService";
import { computed } from "mobx";
import PartnerType from "models/partnerType";
import { partnerToFullName } from "helpers/utils";
import type SupplyPointListItem from "entities/supplyPointListItem";
import HistoricalSupplyPointsService from "services/historicalSupplyPointsService";
import type ModalEditHistoricalSupplyPointsViewModel from "viewModels/products/myProducts/detailPages/customerInfo/modalEditHistoricalSupplyPoints";

interface AddressWithSupplyPoint extends Address {
  supplyPoint: SupplyPointListItem;
}

const navigationName = "account";
@Router.registerRoute({ name: "customerAccount", route: navigationName })
export default class CustomerAccountViewModel extends ConductorSingleChild<ScreenBase> {
  busyWatcher = new BusyWatcher();
  navigationName = navigationName;

  constructor(
    public localization: ILocalizationService,
    public userContext: UserContext,
    public dataSync: DataSyncService,
    public enumsService: EnumsService,
    public historicalSupplyPointsService: HistoricalSupplyPointsService,
    private modalEditCustomerInfoViewModelFactory: ReturnType<typeof ModalEditCustomerInfoViewModel.Factory>,
    private modalEditContactInfoViewModelFactory: ReturnType<typeof ModalEditContactInfoViewModel.Factory>,
    private modalEditMarketingAgreementViewModelFactory: ReturnType<typeof ModalEditMarketingAgreementViewModel.Factory>,
    private modalEditMailingAddressViewModelFactory: ReturnType<typeof ModalEditMailingAddressViewModel.Factory>,
    private modalEditHistoricalSupplyPointsFactory: ReturnType<typeof ModalEditHistoricalSupplyPointsViewModel.Factory>,
    public updateEmailFactory: () => UpdateEmailViewModel
  ) {
    super();
  }

  @bound
  protected async onInitialize() {
    await this.historicalSupplyPointsService.loadHistoricalSupplyPoints();
  }

  get user() {
    return this.userContext.user;
  }

  get partnerId() {
    return this.userContext.activePartnerId;
  }

  get partner() {
    return this.userContext.activePartner;
  }

  get activePartnerFullName() {
    return partnerToFullName(this.enumsService, this.userContext.activePartner);
  }

  @computed
  get supplyPoints() {
    return this.dataSync.supplyPoints;
  }

  @computed
  get pinnedSupplyPoints() {
    return this.supplyPoints.filter(supplyPoint => supplyPoint.pinned);
  }

  @computed
  get showHistoricalSupplyPointsCard() {
    return this.historicalSupplyPointsService.historicalSupplyPoints.length > 0;
  }

  @computed
  get isCompany() {
    if (!this.partner) {
      return false;
    }
    return this.enumsService.getPartnerType(this.partner.partnerTypeId) === PartnerType.Business;
  }

  @computed get postalAddresses(): AddressWithSupplyPoint[] {
    return this.supplyPoints
      .filter(i => i.postalAddress)
      .map(i => ({
        supplyPoint: i,
        ...i.postalAddress!,
      }));
  }

  @bound
  activateEditCustomerInfoModal() {
    if (this.partnerId) {
      return this.tryActivateChild(this.modalEditCustomerInfoViewModelFactory(this.partnerId));
    }
  }

  @bound
  activateEditContactInfoModal() {
    if (this.partnerId) {
      return this.tryActivateChild(this.modalEditContactInfoViewModelFactory(this.partnerId));
    }
  }

  @bound
  activateEditMarketingAgreementModal() {
    if (this.partnerId) {
      return this.tryActivateChild(this.modalEditMarketingAgreementViewModelFactory(this.partnerId));
    }
  }

  @bound
  activateEditMailingAddressModal(item: AddressWithSupplyPoint) {
    if (this.partnerId) {
      // TODO Probably to split this?
      return this.tryActivateChild(this.modalEditMailingAddressViewModelFactory(this.partnerId, item, item.supplyPoint, true));
    }
  }

  @bound
  activateUpdateHistoricalSupplyPoints() {
    if (this.partnerId) {
      return this.tryActivateChild(this.modalEditHistoricalSupplyPointsFactory(this.partnerId));
    }
    return Promise.resolve();
  }

  @bound activateUpdateEmailModal() {
    return this.tryActivateChild(this.updateEmailFactory());
  }

  protected findNavigationChild(_navigationName: string | undefined) {
    return this.activeChild;
  }

  @bound
  @watchBusy
  async updateHistoricalSupplyPoints(supplyPointListItems: SupplyPointListItem[]) {
    if (!this.partnerId) {
      return;
    }

    await this.historicalSupplyPointsService.updateHistoricalSupplyPoints(this.partnerId, supplyPointListItems);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`products.customer_info.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  @bound
  navigate(subPath: string | undefined, params: { manageHistorical?: boolean }): Promise<void> {
    if (params.manageHistorical) {
      return this.activateUpdateHistoricalSupplyPoints();
    }
    return super.navigate(subPath, params);
  }
}

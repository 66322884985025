import { action, computed, observable } from "mobx";
import User from "entities/user";
import Partner from "entities/partner";
import PartnerListItem from "entities/partnerListItem";
import { createURLQuery } from "helpers/utils";
import { bound } from "@frui.ts/helpers";
import { DataEvents } from "./events";
import { IEventBus } from "@emanprague/shared-services";
import { addEcfClientChat } from "ecfChat";

export default class UserContext {
  @computed get isLogged() {
    return !!this.user;
  }

  @observable.ref user?: User;
  @observable.ref activePartner?: Partner;
  @observable.ref partners: PartnerListItem[] = [];

  constructor(eventBus: IEventBus) {
    eventBus.subscribe(DataEvents.activePartnerUpdated, this.passCredentialsToEcfChat);
  }

  @bound
  passCredentialsToEcfChat() {
    if (this.activePartner) {
      const customerNumber = this.activePartner.externalId;
      const customerEmail = this.activePartner.email ?? this.user?.email;

      addEcfClientChat(customerNumber, customerEmail);
    }
  }

  get userRole() {
    return this.user?.role;
  }

  get isAdmin() {
    return this.userRole !== "customer";
  }

  get morphedAdminId() {
    return this.user?.morphedAdminId;
  }

  get activePartnerId() {
    return this.user?.activePartnerId;
  }

  get newSupplyPointLink() {
    const params = {
      token: this.user?.token,
      partnerid: this.activePartner?.externalId,
      "e-mail": this.user?.email,
      process: "new_op",
    };

    return (import.meta.env.REACT_APP_SAM_URL ?? "") + "?" + createURLQuery(params);
  }

  @action.bound
  setMktAgreement(value: boolean) {
    this.activePartner = {
      ...this.activePartner,
      marketingAgreement: value,
    } as Partner;
  }
}

import { View } from "@frui.ts/views";
import { Container } from "inversify";
import { autorun } from "mobx";
import * as Sentry from "@sentry/react";
import UserContext from "services/userContext";
import { BrowserTracing } from "@sentry/react";

export function installMonitoring() {
  // filter error with 401 status
  if (import.meta.env.REACT_APP_SENTRY_DSN && import.meta.env.REACT_APP_CI_COMMIT_SHA) {
    Sentry.init({
      dsn: import.meta.env.REACT_APP_SENTRY_DSN,
      release: "ppas-zp@" + (import.meta.env.REACT_APP_VERSION ?? ""),
      environment: import.meta.env.REACT_APP_STAGE,
      ignoreErrors: [/Request failed with status code 401/, /Precondition Failed/],
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0,
      sampleRate: 0.25,
    });
  }
}

export function initializeMonitoring(container: Container) {
  const userContext = container.get(UserContext);
  autorun(() => {
    const user = userContext.user;
    if (user) {
      Sentry.setUser({
        userId: user.id,
        email: user.email,
        activePartnerId: user.activePartnerId,
      });
    } else {
      Sentry.setUser(null);
    }
  });

  (View as any).defaultProps = {
    onError: (error, info) => Sentry.captureException(error),
  } as Partial<React.ComponentPropsWithoutRef<typeof View>>;
}

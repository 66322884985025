import { IPagingFilter } from "@frui.ts/data";
import Advance from "entities/advance";
import AdvanceList from "entities/advanceList";
import PaymentGateway from "entities/paymentGateway";
import AdvancesFilter from "models/advancesFilter";
import { entityToPlain } from "./helpers";
import RepositoryBase from "./repositoryBase";
import ReqOnlinePayment from "entities/reqOnlinePayment";
import ReqExport from "entities/reqExport";

export default class AdvancesRepository extends RepositoryBase {
  getAdvances(partnerId: number, paging: IPagingFilter, filter?: AdvancesFilter) {
    const query = entityToPlain(filter);

    return this.callApi(api =>
      api.all(`partners/${partnerId}/advances`).getPagedEntities(AdvanceList, x => x.advances, paging, query)
    );
  }

  getAdvanceDetail(partnerId: number, advanceId: number) {
    return this.callApi(api => api.all(`partners/${partnerId}/advances/${advanceId}`).getEntity(Advance));
  }

  setAdvancePay(partnerId: number, advanceId: number, gatewayMethod: ReqOnlinePayment) {
    return this.callApi(api =>
      api.all(`partners/${partnerId}/advances/${advanceId}/online_pay`).postEntity(gatewayMethod, PaymentGateway)
    );
  }

  getQrCodeUrl(partnerId: number, advanceId: number) {
    return (
      `${import.meta.env.REACT_APP_API_URL}${import.meta.env.REACT_APP_API_ENDPOINT}` +
      `/partners/${partnerId}/advances/${advanceId}/qr_code`
    );
  }

  exportAdvances(partnerId: number, reqExport: ReqExport) {
    return this.callApi(api => api.one("partners", partnerId).all("advances/export").postEntity(reqExport));
  }
}

declare global {
  interface Window {
    SapEcfClient?: {
      options: any;
      setUser?: (customerNumber: string, customerEmail: string) => void;
      appCustId: string;
      appEmail: string;
    };
  }
}

export function addEcfClientChat(appCustId: string, appEmail: string) {
  window.SapEcfClient = {
    options: {
      configuration: {
        settingsFile: "/ecf/latest/visitor_portal/my_configuration.json",
      },
      visitorUri: `${import.meta.env.REACT_APP_CHAT_URL}/ecf/latest/visitor_portal/`,
    },
    appCustId,
    appEmail,
  };

  const script = document.createElement("script");
  script.type = "application/javascript";
  script.src = "./Intermediate.js";
  script.async = true;
  document.head.appendChild(script);
}

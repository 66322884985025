import type { TooltipProps } from "@emanprague/ppaskit/dist/Tooltip";
import Tooltip from "@emanprague/ppaskit/dist/Tooltip";
import React from "react";
import { observer } from "mobx-react-lite";

interface ConditionalTooltipProps extends TooltipProps {
  condition: boolean | undefined;
}

export const ConditionalTooltip: React.FunctionComponent<ConditionalTooltipProps> = observer(
  ({ condition, tooltip, id, children, ...rest }) => {
    return condition ? (
      <Tooltip id={id} tooltip={tooltip} wrap {...rest}>
        {children}
      </Tooltip>
    ) : (
      <>{children}</>
    );
  }
);

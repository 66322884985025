import { Consumer, createConsumer, Subscription } from "@rails/actioncable";

export type WebSocketChannel = "PartnerChannel" | "RequestChannel" | "Notification::NotificationChannel";

export default class ServerCommunicationProvider {
  private consumer: Consumer;

  constructor() {
    this.consumer = createConsumer(import.meta.env.REACT_APP_WS_ENDPOINT ?? "/api/ws");
  }

  subscribe(channel: WebSocketChannel, received: (data: any) => void): Promise<Subscription> {
    return new Promise(resolve => {
      const subscription = this.consumer.subscriptions.create(channel, {
        received,
        connected: () => {
          return resolve(subscription);
        },
      });
    });
  }

  start() {
    this.consumer.connection.open();
  }

  stop() {
    this.consumer.connection.close();
  }
}
